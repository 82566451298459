var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("Edit General Info")]),_c('r-third-level-menu')]},proxy:true},{key:"page-content",fn:function(){return [_c('div',{attrs:{"id":"edit-personal-info"}},[(!_vm.isLoadingCurrentUser && _vm.currentUser != null)?_c('div',{attrs:{"id":"general-information"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('div',{staticClass:"buttons",staticStyle:{"border-bottom":"0.1px solid rgba(100, 100, 100, 0.1)"}},[_c('b-button',{attrs:{"type":"is-primary","outlined":"","icon-left":"close","disabled":_vm.isRequesting},on:{"click":function($event){return _vm.$router.push('/settings/personal-info')}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"type":"is-primary","icon-left":"check","native-type":"submit","loading":_vm.isRequesting}},[_vm._v(" Save ")])],1),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('p',{staticClass:"is-size-3 has-text-weight-bold"},[_vm._v(" General Information ")]),_c('ProfilePicture',{attrs:{"user-id":_vm.currentUser.id,"profile-picture-url":_vm.formatProfilePictureUrl(_vm.currentUser)}})],1),_c('div',{staticClass:"column is-12-desktop is-12-mobile is-12-tablet"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"First Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Hannah","expanded":""},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"middle name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Middle Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Jane","expanded":""},model:{value:(_vm.middleName),callback:function ($$v) {_vm.middleName=$$v},expression:"middleName"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Last Name","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Khanwald","expanded":""},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)]}}],null,true)})],1)])]),_c('div',{staticClass:"column is-4-desktop is-6-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"Birth Place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Birth Place","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Bandung"},model:{value:(_vm.birthPlace),callback:function ($$v) {_vm.birthPlace=$$v},expression:"birthPlace"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-8-desktop is-6-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"Birth Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Birth Date","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":"Type or select a date...","icon":"calendar-today","date-formatter":_vm.dateFormatter,"editable":""},model:{value:(_vm.birthDate),callback:function ($$v) {_vm.birthDate=$$v},expression:"birthDate"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Gender","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Gender","expanded":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},_vm._l((_vm.genders),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Nationality","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"Indonesia","expanded":""},model:{value:(_vm.nationality),callback:function ($$v) {_vm.nationality=$$v},expression:"nationality"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-4-desktop is-4-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"Blood Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Blood Type","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Blood Type","expanded":""},model:{value:(_vm.bloodType),callback:function ($$v) {_vm.bloodType=$$v},expression:"bloodType"}},_vm._l((_vm.bloodTypes),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6-desktop is-6-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"Religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Religion","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-select',{attrs:{"placeholder":"Religion","expanded":""},model:{value:(_vm.religion),callback:function ($$v) {_vm.religion=$$v},expression:"religion"}},_vm._l((_vm.religions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(_vm.toSentenceCase(option))+" ")])}),0)],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-6-desktop is-6-tablet is-12-mobile"},[_c('ValidationProvider',{attrs:{"name":"Contact Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Contact Number","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"081122334456","expanded":""},model:{value:(_vm.contactNumber),callback:function ($$v) {_vm.contactNumber=$$v},expression:"contactNumber"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"E-mail","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"johndoe@gmail.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Office Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Office E-mail","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"disabled":"","placeholder":"johndoe@gmail.com"},model:{value:(_vm.officeEmail),callback:function ($$v) {_vm.officeEmail=$$v},expression:"officeEmail"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Current Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Current Address","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"jl.Ciawitali 5A, Cimahi"},model:{value:(_vm.currentAddress),callback:function ($$v) {_vm.currentAddress=$$v},expression:"currentAddress"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column is-12"},[_c('ValidationProvider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Address","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{attrs:{"placeholder":"jl.Ciawitali 5A, Cimahi"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)]}}],null,true)})],1)])])])])]}}],null,false,1337232131)})],1):_vm._e()])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }